import React from "react"

import PropTypes from "prop-types"

import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

const PostItem = ({ node }) => {
  const style = {}
  const title = node.frontmatter.title || node.fields.slug

  return (
    <div style={style}>
      <div key={node.fields.slug}>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={node.fields.urlPath}>
            {title}
          </Link>
        </h3>
        <small style={{color: 'gray'}}>{node.frontmatter.date}</small>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </div>
    </div>
  )
}

PostItem.propTypes = {
  node: PropTypes.object.isRequired,
}

export default PostItem
